import { Modal } from "ant-design-vue"
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { createVNode } from 'vue';
import store from '../store'
import siteinfo from '@/util/global.js'
class ToolModel{
    constructor(){

    }

    /**
     * 确认弹框封装
     * @param {String} title        提示信息
     * @param {String} okText       确认按钮文字描述
     * @param {String} cancelText   取消按钮文字描述
     * @returns 
     */
    confirm(title,okText="确认",cancelText="取消"){
        return new Promise((resolve,reject)=>{
            Modal.confirm({
                title: title,
                icon: createVNode(ExclamationCircleOutlined),
                content: '提示',
                okText: okText,
                cancelText: cancelText,
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    reject(false)
                },
            });
        })
    }

    /**
     * 消息反馈封装
     * @param {String} content 提示信息
     * @param {*} type         类型
     */
    message(content,type="success"){
        switch(type){
            case "success":
                message.success(content)
                break;
            case "warning":
                message.warning(content)
                break;
            case "error":
                message.error(content)
                break;
            default:
                message.error(content)
                break;
        }
    }

	/**
	 * 错误消息提示后不在执行
	 * @param {string} data 
	 * @param {string} title 
	 * @returns 
	 */
	returnMessage(data,title,type="warning"){
		if( !data || data == ""){
			this.message(title,type)
			return false
		}
		return true
	}

    /**
     * 数据过滤
     * @param {数据key} keys 
     * @param {数据值} list 
     * @returns 
     */
    filterForm(keys,list){
		let data = {}
		if( list ){
			keys.map(v=>{
				if( list[v] || list[v]!='' || list[v]==0){
					data[v] = list[v]
				}else{
					data[v] = ''
				}
			})
		}else{
			data = keys 
		}
		return data 
	}

	getStaticSrc(url){
		let { siteroot } = siteinfo.siteinfo
		let src = siteroot.split('//')[0]+'//'+siteroot.split('//')[1].split('/')[0]+'/mini/'		
		return src+url
	}

    /**
     * 获取小程序链接
     * @param {boolean} is_goto_miniapp 显示跳转小程序链接
     * @returns 
     */
    getAppPath(is_goto_miniapp = true){
		let permModule = store.state.modulesPerm
		let path = [
			{name:'首页',path:'pages/index/index'},
			{name:'商城列表',path:'pages/shop/index'},
			{name:'土地列表',path:'pages/land/index'},
			{name:'认养列表',path:'sub_package/adoptV2/pages/index'},
			{name:'认养订单',path:'sub_package/adoptV2/pages/order'},
			{name:'个人中心',path:'pages/user/index'},
			{name:'签到中心',path:'pages/sign/sign'},
			{name:'领券中心',path:'pages/market/coupon'},
			{name:'我的土地',path:'sub_package/land/pages/order'},
			{name:'我的认养',path:'pages/adopt/order'},
			{name:'常见问题',path:'pages/user/question'},
			{name:'购物车',path:'pages/shop/cart'},
			{name:'我的钱包',path:'sub_package/user/pages/wallet/center'},
			{name:'资讯列表',path:'pages/article/list'},
			{name:'监控',path:'pages/live/list'},
			{name:'充值中心',path:'sub_package/user/pages/wallet/recharge'},
			{name:'消息中心',path:'sub_package/contact/pages/list'},
			{name:'我的地块（定制）',path:'sub_package/landCustom/pages/index'},
		]
		if( is_goto_miniapp ){
			path.push({name:'跳转小程序',path:'goto_miniapp'})
			path.push({name:'拨打电话',path:'goto_call'})
			path.push({name:'导航',path:'goto_map'})
		}
		
		if( permModule.multi_merchant == 1 ){
			path.push({name:'商户列表',path:'sub_package/marketing/pages/shop/index'})
			path.push({name:'商户入驻',path:'sub_package/marketing/pages/store/apply'})
		}
		if( permModule.pointsMall == 1 ){
			path.push({name:'积分商城',path:'sub_package/integral/pages/center'})
		}
		
		if( permModule.event == 1 ){
			path.push({name:'活动列表',path:'sub_package/marketing/pages/active/list'})
		}
		if( permModule.live_broadcast == 1 ){
			path.push({name:'直播列表',path:'pages/live/streaming'})
		}
		if( permModule.shop_card == 1){
			path.push({name:'礼品卡',path:'sub_package/marketing/pages/card/bind'})
		}
		if( permModule.marketing_9_lock_draw = 1 ){
			path.push({name:'抽奖',path:'sub_package/marketing/pages/lottery/index'})
		}
		
		if( permModule.bull_market == 1 ){
			path.push({name:'牛交易列表',path:'sub_package/bullMarket/pages/list'})
			path.push({name:'牛交易个人中心',path:'sub_package/bullMarket/pages/center'})
		}
		
		//秒杀
		if( permModule.spike == 1 ){
			path.push({name:'限时秒杀',path:'pages/shop/seckill'})
		}
		//农场圈
		if( permModule.community == 1 ){
			path.push({name:'农场圈',path:'sub_package/user/pages/forum/list'})
		}
		
		//土地游戏样式
		if( permModule.land_game == 1 ){
			path.push({name:'农场土地游戏圈',path:'sub_package/game/pages/index'})
		}
		if( permModule.land_exchange == 1 ){
			path.push({name:'我要换',path:'sub_package/land/pages/change/list'})
		}
		
		return path
	}

    /**
     * 获取小程序个人中心页面功能板块
     * @returns 
     */
    getWxappCenterPath(){
        let permModule = store.state.modulesPerm
		let center = [
			{manager:1, icon:'ri-layout-masonry-line',name:'我的土地','app_show':true,'wxapp_show':true,path:'/sub_package/land/pages/order'},
			{manager:1, icon:'ri-mickey-line',name:'认养订单','app_show':true,'wxapp_show':true,path:'/sub_package/adoptV2/pages/order'},
			{manager:1, icon:'ri-coupon-line',name:'领券','app_show':true,'wxapp_show':true,path:'/pages/market/coupon'},
			{manager:1, icon:'ri-map-pin-line',name:'收货地址','app_show':true,'wxapp_show':true,path:'/pages/user/address'},
			{manager:1, icon:'ri-question-line',name:'常见问题','app_show':true,'wxapp_show':true,path:'/pages/user/question'},
			{manager:1, icon:'ri-coupon-line',name:'我的优惠券','app_show':true,'wxapp_show':true,path:'/pages/market/my_coupon'},
			{manager:1, icon:'ri-shopping-cart-line',name:'购物车','app_show':true,'wxapp_show':true,path:'/pages/shop/cart'},
			{manager:1, icon:'ri-file-list-3-line',name:'服务订单','app_show':true,'wxapp_show':true,path:'/sub_package/land/pages/server-order'},
			{manager:1, icon:'ri-customer-service-2-line',name:'专属客服','app_show':true,'wxapp_show':true,path:'exclusive_service'},
		]
		if( permModule.event == 1 ){
			center.push( {manager:1,icon:'ri-bookmark-3-line',name:'活动订单','app_show':true,'wxapp_show':true,path:'/sub_package/marketing/pages/active/order'})
		}
		if( permModule.sales_share == 1 ){
			center.push({manager:1,icon:'ri-share-line',name:'分销中心','app_show':true,'wxapp_show':true,path:'/sub_package/marketing/pages/distribution/index'})
		}
		if(permModule.multi_merchant == 1 ){
			center.push({manager:1,icon:'ri-store-3-line',name:'农场入驻','app_show':true,'wxapp_show':true,path:'/sub_package/marketing/pages/store/apply'})
			center.push({manager:1,icon:'ri-store-3-line',name:'全部店铺','app_show':true,'wxapp_show':true,path:'/sub_package/marketing/pages/shop/index'})
		}
		if( permModule.land_exchange == 1 ){
		 	center.push({manager:1,icon:'ri-exchange-line',name:'我要换','app_show':true,'wxapp_show':true,path:'/sub_package/land/pages/change/list'})
		}
		if( permModule.land_2d == 1 ){
			center.push({manager:1,icon:'ri-file-list-3-line',name:'土地工单','app_show':true,'wxapp_show':true,path:'/sub_package/landCustom/pages/work'})
		}
		return center
	}

	getExpressCompany(){
		return [
			{express_company:'商家配送',express_code:'merchantdelivery'},
			{express_company:'顺丰',express_code:'SF'},
			{express_company:'百世快递',express_code:'HTKY'},
			{express_company:'中通快递',express_code:'ZTO'},
			{express_company:'申通快递',express_code:'STO'},
			{express_company:'圆通速递',express_code:'YTO'},
			{express_company:'韵达速递',express_code:'YD'},
			{express_company:'邮政快递包裹',express_code:'YZPY'},
			{express_company:'EMS',express_code:'EMS'},
			{express_company:'天天快递',express_code:'HHTT'},
			{express_company:'京东快递',express_code:'JD'},
			{express_company:'优速快递',express_code:'UC'},
			{express_company:'德邦快递',express_code:'DBL'},
			{express_company:'宅急送',express_code:'ZJS'},
			{express_company:'TNT快递',express_code:'TNT'},
			{express_company:'UPS',express_code:'UPS'},
			{express_company:'DHL',express_code:'DHL'},
			{express_company:'FEDEX联邦(国内件)',express_code:'FEDEX'},
			{express_company:'FEDEX联邦(国际件)',express_code:'FEDEX_GJ'},
		]
	}
}
const tool = new ToolModel()
export default tool