import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import storeRouter from './router/storePerm'
import Util from './util/util.js'
import Status from './util/status.js'
import GizRequest from './model/device/gizRequest.js'
import Clipboard from 'v-clipboard3';


const app = createApp(App);
app.config.productionTip = false;
app.use(Antd);
app.use(router)
app.use(store)
app.use(Clipboard);
app.config.globalProperties.$util = new Util()
app.config.globalProperties.$status =Status
app.config.globalProperties.$gizRequest = new GizRequest()		//物联网相关js

//按钮权限指令
app.directive('has',{
	mounted(el,binding) {
		let { value } = binding;
		let btnRoute = JSON.parse(sessionStorage.getItem('__BTNPERM__')) || {}
		let btnPerm = []
		if( value.plat && value.plat == 1 ) return true
		if( btnRoute){
			btnPerm = [...btnRoute.web,...btnRoute.farm_admin]
		}
		if( !btnPerm.includes(value.action) ){
			console.log(value.action+':无权限');
			el.parentNode.removeChild(el)
		}
	},
})

app.directive('clickoutside',{
	// 初始化指令
	mounted(el, binding, vnode) {
		function documentHandler(e) {
			// 这里判断点击的元素是否是本身，是本身，则返回
			if (el.contains(e.target)) {
				return false;
			}
			// 判断指令中是否绑定了函数
			if( binding.value ){
				binding.value(e);
			}
		}
		// 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
		el.__vueClickOutside__ = documentHandler;
		document.addEventListener('click', documentHandler);
	},
	unmounted(el, binding) {
		// 解除事件监听
		document.removeEventListener('click', el.__vueClickOutside__);
		delete el.__vueClickOutside__;
	},
})


//路由全局守卫
router.beforeEach((to,from,next)=>{
	if( localStorage.getItem("token")){
		
		if( to.path == '/' || to.path == '/login' || to.path == '/register' || to.path =='/login/404' ||
			to.path =='not-found'||to.path.includes('/store/') || to.path.includes('/adhibition/digitalFarm/')){
			next()
		}else{
			
			let userInfo = JSON.parse(localStorage.getItem("kdFarmUserInfo"))
			if( userInfo && userInfo.is_shop == 1 ){  //多商户
				let perm =  storeRouter.perm
				store.dispatch('setPermissionList',perm )
				if(to.path =='/storeapp' || to.path.includes('/many_store/')){
					next()
				}else{
					next({	path: '/login/404',});
				}
			}else{
				if( userInfo.rank !=1 ){  //不是超级管理员

					if(to.path.includes('/shop/')){
						next()
					}else{
						//防止第一次进入没有权限的问题
						if( to.path == '/miniapp') {
							console.log('aaa');
							next()
							return
						}
						let path = JSON.parse(sessionStorage.getItem('__BTNPERM__')) || []
						let path_1 = [...path.farm_admin]
						let arr = path_1.filter(item=>item == to.path)
						if( arr && arr.length > 0 ){
							next()
						}else{
							next({path:"/login/404"})
						}
					}
					return
				}

				//超级管理员
				if( to.path == '/admin/index' || to.path == '/miniapp') {
					next()
					return
				}else{
					let path = JSON.parse(sessionStorage.getItem('__BTNPERM__')) || []
					let path_1 = [...path.web,...path.farm_admin]
					let arr = path_1.filter(item=>item == to.path)
					if( arr && arr.length > 0 ){
						next()
					}else{
						next({path:"/login/404"})
					}
				}
			}
		}
	}else{
		if(to.path == '/login' || to.path.includes('/store/')){
			next()
		}else{
			if( to.path == '/register'){
				next()
			}else{
				next({path: '/login'});
			}
		}
	}

})


import kdImgSelect from '@/components/public/kd-img-select.vue'
import kdHideSensitivityInput from '@/components/public/kd-hide-sensitivity-input.vue'
import uploadCert from '@/components/public/upload-cert.vue'
import kdButton from '@/components/public/kd-button.vue'
import kdSwitch from '@/components/public/kd-switch.vue'
import kdMap from '@/components/public/kd-map.vue'
import tinymceEditor from '@/components/public/tinymce-editor.vue'
import comSubmitBtn from '@/components/form/com-submit-btn.vue'
import kdColor from '@/components/public/kd-color.vue'
app.component('kd-img-select',kdImgSelect)
app.component('kd-hide-sensitivity-input',kdHideSensitivityInput)
app.component('upload-cert',uploadCert)
app.component('kd-button',kdButton)
app.component('kd-switch',kdSwitch)
app.component('kd-map',kdMap)
app.component('tinymce-editor',tinymceEditor)
app.component('com-submit-btn',comSubmitBtn)
app.component('kd-color',kdColor)
app.mount('#app')
// createApp(App).mount('#app')

