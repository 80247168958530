import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import NotFound from '@/page/login/404'
const routerHistory = createWebHistory()
// 兼容路由版本过高导致出现报错
const originalPush = createRouter.prototype.push
createRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}        

import commonView from '@/page/view'
//hidden 1表示不展示  2 总后台  3小程序后台
const router = createRouter({
	routes: [
		{
			name: '登录',
			path: '/login',
			component: () => import('@/page/login/login')
		},
		{ name: '注册', path: '/register', component: () => import('@/page/login/register') },
		{ name: '商户登陆', path: '/store/:id', component: () => import('@/page/login/store') },
		{ name: '默认访问', path: '/', redirect: '/login' },
		{ name:'404',path:'/login/404',component: () => import('@/page/login/404')},
		{ path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
  		{ path: '/:pathMatch(.*)', name: 'bad-not-found', component: NotFound },
		{name:'miniapp自定义页面',path:'/set/customPage/homePage',component:()=>import('@/page/miniapp/set/customPage/homePage')},
		{name:'pc自定义页面',path:'/website/webPage',component:()=>import('@/page/miniapp/website/webPage')},
		{name:'sass-bar',path:"/shop/common",component:()=>import('@/page/shop/common'),children:[
			{name:'sass-首页',path:'/shop/index',component:()=>import('@/page/shop/index')},
			{name:'sass-创建店铺',path:'/shop/createShop',component:()=>import('@/page/shop/createShop')},
			{name:'sass-个人信息',path:'/shop/personal',component:()=>import('@/page/shop/personal')},
			{name:'sass-员工管理',path:'/shop/employee',component:()=>import('@/page/shop/employee')},
			{name:'sass-店铺续费',path:'/shop/shopRenew',component:()=>import('@/page/shop/shopRenew')},
		]},
		{
			path:'/common',
			component:()=>import('@/page/admin/common'),
			children:[
				{name:'总览',path:'/admin/index',component:()=>import('@/page/admin/index')},
				{name:'sass插件',path:'',component:commonView,children:[
					{name:'插件-云市场',path:'/admin/adhibition/list',component:()=>import('@/page/admin/adhibition/list')},
					{name:'插件-我的插件',path:'/admin/adhibition/my',component:()=>import('@/page/admin/adhibition/my')},
					{name:'插件-插件订单',path:'/admin/adhibition/order',component:()=>import('@/page/admin/adhibition/order')},
				]},
				{name:'站点',path:'',component:commonView,children:[
					{name:'站点设置',path:'/admin/siteSet/webSite',component:()=>import('@/page/admin/siteSet/webSite')},
					{name:'系统更新',path:'/admin/siteSet/system_update',component:()=>import('@/page/admin/siteSet/system_update')},
					{name:'云储存',path:'/admin/siteSet/cloudoss',component:()=>import('@/page/admin/siteSet/cloudoss')},
					{name:'支付配置',path:'/admin/siteSet/alipay',component:()=>import('@/page/admin/siteSet/alipay')},
					{name:'短信配置',path:'/admin/siteSet/short_msg',component:()=>import('@/page/admin/siteSet/short_msg')},
					{name:'基本设置',path:'/admin/siteSet/basic_set',component:()=>import('@/page/admin/siteSet/basic_set')},
					{name:'微信开放平台',path:'/admin/siteSet/wechat',component:()=>import('@/page/admin/siteSet/wechat')},
					{name:'商户配置',path:'/admin/siteSet/tenant',component:()=>import('@/page/admin/siteSet/tenant')},
				]},
				{name:'权限管理',path:'',component:commonView,children:[
					{name:'角色管理',path:'/admin/perm/role_list',component:()=>import('@/page/admin/perm/role_list')},
					{name:'新增/编辑角色',path:'/admin/perm/role_add',component:()=>import('@/page/admin/perm/role_add')},
					{name:'员工列表',path:'/admin/employee/employee_list',component:()=>import('@/page/admin/employee/employee_list')},
					{name:'详情',path:'/admin/employee/employee_info',component:()=>import('@/page/admin/employee/employee_info')},
					{name:'权限菜单',path:'/admin/perm/perm_menu',component:()=>import('@/page/admin/perm/perm_menu')},
				]},
				{name:'代理商',path:'',component:commonView,children:[
					{name:'代理商-列表',path:'/admin/agency/agency_list',component:()=>import('@/page/admin/agency/agency_list')},
					{name:'代理商-详情',path:'/admin/agency/agency_info',component:()=>import('@/page/admin/agency/agency_info')},
				]},
				{name:'商户',path:'',component:commonView,children:[
					{name:'商户-列表',path:'/admin/user/user_list',component:()=>import('@/page/admin/user/user_list')},
					{name:'商户-详情',path:'/admin/user/user_info',component:()=>import('@/page/admin/user/user_info')},
				]},
				{name:'服务',path:'',component:commonView,children:[
					{name:'分支管理',path:'/admin/server/branch_list',component:()=>import('@/page/admin/server/branch_list')},
					{name:'套餐管理',path:'/admin/server/meal_list',component:()=>import('@/page/admin/server/meal_list')},
					{name:'续费订单',path:'/admin/order/renew_order',component:()=>import('@/page/admin/order/renew_order')},
				]},
				{name:'sass订单',path:'',component:commonView,children:[
					{name:'sass订单-订单列表',path:'/admin/order/order_list',component:()=>import('@/page/admin/order/order_list')},
					// {name:'订单详情',path:'/admin/order/order_detail',component:()=>import('@/page/admin/order/order_detail')},
				]},
			
				{name:'域名',path:'',component:commonView,children:[
					{name:'官网-审核',path:'/admin/domain/website',component:()=>import('@/page/admin/domain/website')},
					{name:'APP-审核',path:'/admin/domain/appsite',component:()=>import('@/page/admin/domain/appsite')}
				]},
				{name:'应用',path:'',component:commonView,children:[
					{name:'我的应用',path:'/admin/app/app_list',component:()=>import('@/page/admin/program/list')},
					{name:'审核列表',path:'/admin/app/check_list',component:()=>import('@/page/admin/program/check_list')},
					{name:'应用管理',path:'/admin/app/app_manage',component:()=>import('@/page/admin/program/app_manage')},
					{name:'操作记录',path:'/admin/app/operat_record',component:()=>import('@/page/admin/program/operat_record')},
					{name:'新增/编辑应用',path:'/admin/app/app_edit',component:()=>import('@/page/admin/program/app_edit')},
				]},
				{name:'消息列表',path:'/admin/message/message_list',component:()=>import('@/page/admin/message/message_list')},
				{name:'用户详情',path:'/admin/user_detail',component:()=>import('@/page/admin/user_detail')},
			]
		},
		{
			// name:'小程序管理后台',
			path:'/miniapp',
			component:()=>import('@/page/miniapp/common'),
			children:[
				{name:'应用总览',path:'/miniapp',component:()=>import('@/page/miniapp/index')},
				{name:'应用-设置',path:'',component:commonView ,children:[
					{name:'设置-农场设置',path:'/set/farm',component:()=>import('@/page/miniapp/set/farm')},
					{name:'微信小程序',path:'/set/miniappSet',component:()=>import('@/page/miniapp/set/miniappSet')},
					{name:'微信配置',path:'/set/wechat',component:()=>import('@/page/miniapp/set/wechat')},
					{name:'问题分类',path:'/set/question/typeList',component:()=>import('@/page/miniapp/set/question/typeList')},
					{name:'问题列表',path:'/set/question/questionList',component:()=>import('@/page/miniapp/set/question/questionList')},
					{name:'问题列表新增/编辑',path:'/set/question/questionEdit',component:()=>import('@/page/miniapp/set/question/questionEdit')},
					{name:'图文列表',path:'/set/article/articleList',component:()=>import('@/page/miniapp/set/article/articleList')},
					{name:'图文列表新增/编辑',path:'/set/article/articleEdit',component:()=>import('@/page/miniapp/set/article/articleEdit')},
					{name:'图文分类',path:'/set/article/typeList',component:()=>import('@/page/miniapp/set/article/typeList')},
					{name:'运费规则-新增/编辑',path:'/set/frieght/edit',component:()=>import('@/page/miniapp/set/frieght/edit')},
					{name:'通知配置',path:'/set/notice',component:()=>import('@/page/miniapp/set/notice')},
					{name:'应用-自定义页面',path:'/set/customPage/pageList',component:()=>import('@/page/miniapp/set/customPage/pageList'),meta:{keepAlive:true}},
					{name:'应用-配送设置',path:'/set/delivery_set',component:()=>import('@/page/miniapp/set/delivery_set')},
					{name:'APP配置',path:'/set/app_set',component:()=>import('@/page/miniapp/set/app_set')}
				]},
				{name:'商品',path:'',component:commonView ,children:[
					{name:'商品列表',path:'/goods/list',component:()=>import('@/page/miniapp/goods/goodsList'),meta:{keepAlive:true}},
					{name:'商品编辑',path:'/goods/goodsEdit',component:()=>import('@/page/miniapp/goods/goodsEdit')},
					{name:'商品分类',path:'/goods/typeList',component:()=>import('@/page/miniapp/goods/typeList')},
					{name:'商品服务',path:'/goods/serviceList',component:()=>import('@/page/miniapp/goods/serviceList')},
					{name:'商城设置',path:'/goods/set',component:()=>import('@/page/miniapp/goods/set')},
				]},
				{name:'订单',path:'',component:commonView ,children:[
					{name:'订单-列表',path:'/order/orderList',component:()=>import('@/page/miniapp/goods/orderList'),meta:{keepAlive:true}},
					{name:'订单-详情',path:'/order/detail',component:()=>import('@/page/miniapp/goods/orderDetail')},
					{name:'售后订单',path:'/order/sale',component:()=>import('@/page/miniapp/goods/sale')},
					{name:'售后仲裁订单',path:'/order/arbitration',component:()=>import('@/page/miniapp/goods/arbitration')},
				]},
				{name:'土地',path:'',component:commonView ,children:[
					{name:'土地管理',path:'/land/landList',component:()=>import('@/page/miniapp/land/landList'),meta:{keepAlive:true}},
					{name:'土地编辑',path:'/land/landEdit',component:()=>import('@/page/miniapp/land/landEdit')},
					{name:'土地分类',path:'/land/typeList',component:()=>import('@/page/miniapp/land/typeList')},
					{name:'种子管理',path:'/land/seedList',component:()=>import('@/page/miniapp/land/seedList')},
					{name:'种子编辑',path:'/land/seedEdit',component:()=>import('@/page/miniapp/land/seedEdit')},
					{name:'租地订单',path:'/land/land_order',component:()=>import('@/page/miniapp/land/land_order')},
					{name:'租地订单详情',path:'/land/orderDetail',component:()=>import('@/page/miniapp/land/orderDetail')},
					{name:'种植管理',path:'/land/orderList',component:()=>import('@/page/miniapp/land/orderList'),meta:{keepAlive:true}},
					{name:'租赁协议',path:'/land/land_agreement',component:()=>import('@/page/miniapp/land/land_agreement')},
					{name:'服务管理',path:'/land/service',component:()=>import('@/page/miniapp/land/service')},
					{name:'服务编辑',path:'/land/service_edit',component:()=>import('@/page/miniapp/land/service_edit')},
					{name:'土地-配送订单',path:'/land/delivery',component:()=>import('@/page/miniapp/land/delivery')},
					{name:'种植订单详情',path:'/land/plant_detail',component:()=>import('@/page/miniapp/land/plant_detail')},
					{name:'配送订单详情',path:'/land/delivery_detail',component:()=>import('@/page/miniapp/land/delivery_detail')},
					{name:'工单',path:'/land/workOrder',component:()=>import('@/page/miniapp/land/workOrder')},
					{name:'添加编辑土地订单服务',path:'/land/addWorkService',component:()=>import('@/page/miniapp/land/addWorkService')},
					{name:'巡检员',path:'/land/listPolling',component:()=>import('@/page/miniapp/land/listPolling')},
					{name:'交换订单',path:'/land/change',component:()=>import('@/page/miniapp/land/change')},
				]},
				{name:'认养',path:'',component:commonView ,children:[
					{name:'认养列表',path:'/adopt/list',component:()=>import('@/page/miniapp/adopt/list'),meta:{keepAlive:true}},
					{name:'认养列表编辑',path:'/adopt/edit',component:()=>import('@/page/miniapp/adopt/edit')},
					{name:'认养分类',path:'/adopt/type',component:()=>import('@/page/miniapp/adopt/type')},
					{name:'认养订单',path:'/adopt/orderList',component:()=>import('@/page/miniapp/adopt/orderList'),meta:{keepAlive:true}},
					{name:'认养订单详情',path:'/adopt/orderDetail',component:()=>import('@/page/miniapp/adopt/orderDetail')},
					{name:'认养协议',path:'/adopt/adopt_agreement',component:()=>import('@/page/miniapp/adopt/adopt_agreement')},
					{name:'认养管理',path:'/adopt/manager',component:()=>import('@/page/miniapp/adopt/manager')},
					{name:'添加操作类型',path:'/adopt/work_order',component:()=>import('@/page/miniapp/adopt/work_order')},
					{name:'配送订单',path:'/adopt/delivery_order',component:()=>import('@/page/miniapp/adopt/delivery_order')},
					{name:'认养合同',path:'/adopt/contract',component:()=>import('@/page/miniapp/adopt/contract')},
					{name:'认养合同新增/编辑',path:'/adopt/contract_edit',component:()=>import('@/page/miniapp/adopt/contract_edit')},
				]},
				{name:'会员',path:'',component:commonView ,children:[
					{name:'会员列表',path:'/account/accountList',component:()=>import('@/page/miniapp/account/accountList')},
					{name:'会员详情',path:'/account/account_detail',component:()=>import('@/page/miniapp/account/account_detail')},
					{name:'签到记录',path:'/account/signList',component:()=>import('@/page/miniapp/account/signList')},
					{name:'提现记录',path:'/account/withdrawList',component:()=>import('@/page/miniapp/account/withdrawList')},
					{name:'会员等级',path:'/account/accountLevelList',component:()=>import('@/page/miniapp/account/accountLevelList')},
					{name:'余额记录',path:'/account/moneyRecord',component:()=>import('@/page/miniapp/account/moneyRecord')},
					{name:'认证列表',path:'/account/real_auth',component:()=>import('@/page/miniapp/account/real_auth')},
					{name:'充值记录',path:'/account/recharge_record',component:()=>import('@/page/miniapp/account/recharge_record')},
					{name:'积分记录',path:'/account/scoreRecord',component:()=>import('@/page/miniapp/account/scoreRecord')},
				]},
				{name:'监控',path:'',component:commonView ,children:[
					{name:'监控列表',path:'/live/liveList',component:()=>import('@/page/miniapp/live/liveList')},
					{name:'监控编辑',path:'/live/liveEdit',component:()=>import('@/page/miniapp/live/liveEdit')},
					{name:'监控分类',path:'/live/typeList',component:()=>import('@/page/miniapp/live/typeList')},
					{name:'监控预览',path:'/live/live_preview',component:()=>import('@/page/miniapp/live/live_preview')},
					{name:'设备-集中监测',path:'/live/device_center',component:()=>import('@/page/miniapp/live/device_center')},
					{name:'设备-设备详情',path:'/live/device_detail',component:()=>import('@/page/miniapp/live/device_detail')},
					{name:'设备-云授权',path:'/live/kd-cloud-auth',component:()=>import('@/page/miniapp/live/kd-cloud-auth')},
					{name:'设备列表',path:'/live/monitor_list',component:()=>import('@/page/miniapp/live/monitor_list'),meta:{keepAlive:true}},
					{name:'设备详情',path:'/live/monitor_detail',component:()=>import('@/page/miniapp/live/monitor_detail')},
					{name:'监控设置',path:'/live/set',component:()=>import('@/page/miniapp/live/set')},
				]},
				{name:'营销',path:'',component:commonView ,children:[
					// {name:'营销中心',path:'/common/my_plugin',component:()=>import('@/page/common/my_plugin')},
					{name:'优惠券',path:'/marketing/couponList',component:()=>import('@/page/miniapp/adhibition/coupon/list')},
					{name:'优惠券-编辑',path:'/marketing/couponEdit',component:()=>import('@/page/miniapp/adhibition/coupon/edit')},
					{name:'返现-列表',path:'/marketing/cashback/list',component:()=>import('@/page/miniapp/adhibition/cashback/list')},
					{name:'返现-新增/编辑',path:'/marketing/cashback/edit',component:()=>import('@/page/miniapp/adhibition/cashback/edit')},
					{name:'返现-资金日志',path:'/marketing/cashback/fund_log',component:()=>import('@/page/miniapp/adhibition/cashback/fund_log')},
					{name:'抽奖-设置',path:'/marketing/lottery/set',component:()=>import('@/page/miniapp/adhibition/lottery/set')},
					{name:'抽奖-列表',path:'/marketing/lottery/list',component:()=>import('@/page/miniapp/adhibition/lottery/list')},
					{name:'抽奖-新增/编辑',path:'/marketing/lottery/edit',component:()=>import('@/page/miniapp/adhibition/lottery/edit')},
					{name:'抽奖-订单',path:'/marketing/lottery/order',component:()=>import('@/page/miniapp/adhibition/lottery/order')},
					{name:'商户-基本设置',path:'/adhibition/store/set',component:()=>import('@/page/miniapp/adhibition/store/set')},
					{name:'商户-商户列表',path:'/adhibition/store/list',component:()=>import('@/page/miniapp/adhibition/store/list')},
					{name:'商户-入驻审核',path:'/adhibition/store/enter_audit',component:()=>import('@/page/miniapp/adhibition/store/enter_audit')},
					{name:'商户-商户审核',path:'/adhibition/store/store_audit',component:()=>import('@/page/miniapp/adhibition/store/store_audit')},
					{name:'商户-商户详情',path:'/adhibition/store/store_detail',component:()=>import('@/page/miniapp/adhibition/store/store_detail')},
					{name:'商户-商户添加',path:'/adhibition/store/store_add',component:()=>import('@/page/miniapp/adhibition/store/store_add')},
					{name:'商户-商品审核',path:'/adhibition/store/goodsCheck',component:()=>import('@/page/miniapp/adhibition/store/goodsCheck')},
					{name:'商户-商户提现',path:'/adhibition/store/withdraw',component:()=>import('@/page/miniapp/adhibition/store/withdraw')},
					{name:'商户-认证中心',path:'/adhibition/store/cert',component:()=>import('@/page/miniapp/adhibition/store/cert')},
					{name:'商户-认证新增/编辑',path:'/adhibition/store/cert_edit',component:()=>import('@/page/miniapp/adhibition/store/cert_edit')},
					{name:'商户-认证列表',path:'/adhibition/store/cert_apply',component:()=>import('@/page/miniapp/adhibition/store/cert_apply')},
					{name:'商户-认证订单',path:'/adhibition/store/cert_order',component:()=>import('@/page/miniapp/adhibition/store/cert_order')},
					{name:'商户-等级',path:'/adhibition/store/level',component:()=>import('@/page/miniapp/adhibition/store/level')},
					{name:'商户-保证金-等级',path:'/adhibition/store/bond_level',component:()=>import('@/page/miniapp/adhibition/store/bond_level')},
					{name:'商户-保证金-等级新增/编辑',path:'/adhibition/store/bond_level_edit',component:()=>import('@/page/miniapp/adhibition/store/bond_level_edit')},
					{name:'商户-保证金-账单',path:'/adhibition/store/bond_bill',component:()=>import('@/page/miniapp/adhibition/store/bond_bill')},
					{name:'商户-保证金-订单',path:'/adhibition/store/bond_order',component:()=>import('@/page/miniapp/adhibition/store/bond_order')},
					{name:'门店-列表',path:'/adhibition/outlet/list',component:()=>import('@/page/miniapp/adhibition/outlet/list')},
					{name:'门店-新增/编辑',path:'/adhibition/outlet/edit',component:()=>import('@/page/miniapp/adhibition/outlet/edit')},
					{name:'门店-分类',path:'/adhibition/outlet/type',component:()=>import('@/page/miniapp/adhibition/outlet/type')},
					{name:'门店-打印机',path:'/adhibition/outlet/print',component:()=>import('@/page/miniapp/adhibition/outlet/print')},
					{name:'分销商-基本设置',path:'/adhibition/distribution/basic_set',component:()=>import('@/page/miniapp/adhibition/distribution/basic_set')},
					{name:'分销商-分销商管理',path:'/adhibition/distribution/list',component:()=>import('@/page/miniapp/adhibition/distribution/list')},
					{name:'分销商-提现管理',path:'/adhibition/distribution/withdraw',component:()=>import('@/page/miniapp/adhibition/distribution/withdraw')},
					{name:'分销商-订单列表',path:'/adhibition/distribution/order',component:()=>import('@/page/miniapp/adhibition/distribution/order')},
					{name:'分销商-等级',path:'/adhibition/distribution/level',component:()=>import('@/page/miniapp/adhibition/distribution/level')},
					{name:'分销商-等级规则',path:'/adhibition/distribution/rule',component:()=>import('@/page/miniapp/adhibition/distribution/rule')},
					{name:'分销商-资金明细',path:'/adhibition/distribution/record',component:()=>import('@/page/miniapp/adhibition/distribution/record')},
					{name:'批发-商品列表',path:'/wholesale/goodsList',component:()=>import('@/page/miniapp/adhibition/wholesale/goodsList')},
					{name:'批发-批发订单',path:'/wholesale/order',component:()=>import('@/page/miniapp/adhibition/wholesale/order')},
					{name:'批发-批发订单详情',path:'/wholesale/orderDetail',component:()=>import('@/page/miniapp/adhibition/wholesale/orderDetail')},
					{name:'礼品卡-列表',path:'/adhibition/card/list',component:()=>import('@/page/miniapp/adhibition/card/list')},
					{name:'礼品卡-礼品卡新增/编辑',path:'/adhibition/card/card_edit',component:()=>import('@/page/miniapp/adhibition/card/card_edit')},
					{name:'礼品卡-包装',path:'/adhibition/card/package',component:()=>import('@/page/miniapp/adhibition/card/package')},
					{name:'礼品卡-设置',path:'/adhibition/card/set',component:()=>import('@/page/miniapp/adhibition/card/set')},
					{name:'直播',path:'/adhibition/streaming/index',component:()=>import('@/page/miniapp/adhibition/streaming/index')},
					{name:'活动-列表',path:'/adhibition/active/list',component:()=>import('@/page/miniapp/adhibition/active/list')},
					{name:'活动-票种',path:'/adhibition/active/ticket',component:()=>import('@/page/miniapp/adhibition/active/ticket')},
					{name:'活动-分类',path:'/adhibition/active/type',component:()=>import('@/page/miniapp/adhibition/active/type')},
					{name:'活动-新增-编辑',path:'/adhibition/active/edit',component:()=>import('@/page/miniapp/adhibition/active/edit')},
				    {name:'活动-订单',path:'/adhibition/active/order',component:()=>import('@/page/miniapp/adhibition/active/order')},
				    {name:'活动-设置',path:'/adhibition/active/set',component:()=>import('@/page/miniapp/adhibition/active/set')},
				    {name:'牛市-分类',path:'/adhibition/bullMarket/category',component:()=>import('@/page/miniapp/adhibition/bullMarket/category')},
				    {name:'牛市-畜牧',path:'/adhibition/bullMarket/bull',component:()=>import('@/page/miniapp/adhibition/bullMarket/bull')},
				    {name:'牛市-畜牧编辑',path:'/adhibition/bullMarket/bull_edit',component:()=>import('@/page/miniapp/adhibition/bullMarket/bull_edit')},
				    {name:'牛市-账户',path:'/adhibition/bullMarket/account',component:()=>import('@/page/miniapp/adhibition/bullMarket/account')},
				    {name:'牛市-订单',path:'/adhibition/bullMarket/order',component:()=>import('@/page/miniapp/adhibition/bullMarket/order')},
				    {name:'牛市-设置',path:'/adhibition/bullMarket/set',component:()=>import('@/page/miniapp/adhibition/bullMarket/set')},
				    {name:'牛市-充值列表',path:'/adhibition/bullMarket/recharge',component:()=>import('@/page/miniapp/adhibition/bullMarket/recharge')},
				    {name:'牛市-提现列表',path:'/adhibition/bullMarket/withdraw',component:()=>import('@/page/miniapp/adhibition/bullMarket/withdraw')},
				    {name:'牛市-银行监管',path:'/adhibition/bullMarket/supervise',component:()=>import('@/page/miniapp/adhibition/bullMarket/supervise')},
				    {name:'牛市-商家列表',path:'/adhibition/bullMarket/merchant',component:()=>import('@/page/miniapp/adhibition/bullMarket/merchant')},
				    {name:'积分商城-分类',path:'/adhibition/integral/type',component:()=>import('@/page/miniapp/adhibition/integral/type')},
				    {name:'积分商城-商品',path:'/adhibition/integral/list',component:()=>import('@/page/miniapp/adhibition/integral/list')},
				    {name:'积分商城-商品编辑',path:'/adhibition/integral/edit',component:()=>import('@/page/miniapp/adhibition/integral/edit')},
				    {name:'积分商城-订单',path:'/adhibition/integral/order',component:()=>import('@/page/miniapp/adhibition/integral/order')},
				    {name:'限时秒杀-活动列表',path:'/adhibition/seckill/list',component:()=>import('@/page/miniapp/adhibition/seckill/list')},
				    {name:'限时秒杀-活动编辑',path:'/adhibition/seckill/edit',component:()=>import('@/page/miniapp/adhibition/seckill/edit')},
				    {name:'限时秒杀-设置',path:'/adhibition/seckill/set',component:()=>import('@/page/miniapp/adhibition/seckill/set')},
				    {name:'免费领土地',path:'/adhibition/freeLand/event',component:()=>import('@/page/miniapp/adhibition/freeLand/event')},
				    {name:'免费领土地-编辑',path:'/adhibition/freeLand/edit',component:()=>import('@/page/miniapp/adhibition/freeLand/edit')},
				    {name:'免费领土地-领取列表',path:'/adhibition/freeLand/get',component:()=>import('@/page/miniapp/adhibition/freeLand/get')},
				    {name:'农场社区',path:'/adhibition/forum/list',component:()=>import('@/page/miniapp/adhibition/forum/list')},
				    {name:'土地定制-设置',path:'/adhibition/landCustom/set',component:()=>import('@/page/miniapp/adhibition/landCustom/set')},
				    {name:'土地定制-工单列表',path:'/adhibition/landCustom/work',component:()=>import('@/page/miniapp/adhibition/landCustom/work')},
				    {name:'土地定制-装饰管理',path:'/adhibition/landCustom/decorate',component:()=>import('@/page/miniapp/adhibition/landCustom/decorate')},
					{name:'土地定制-操作设置',path:'/adhibition/landCustom/operate',component:()=>import('@/page/miniapp/adhibition/landCustom/operate')},
					
					
					// {name:'数字农场-首页',path:'/adhibition/digitalFarm/index',component:()=>import('@/page/miniapp/adhibition/digitalFarm/index')},
				
				]},
				{name:'资 金',path:'',component:commonView,children:[
					{name:'资产总览',path:'/fund/overview',component:()=>import('@/page/miniapp/fund/overview')},
					{name:'账单记录',path:'/fund/bill_record',component:()=>import('@/page/miniapp/fund/bill_record')},
					{name:'交易记录',path:'/fund/trade_record',component:()=>import('@/page/miniapp/fund/trade_record')},
					{name:'保证金',path:'/fund/bond_record',component:()=>import('@/page/miniapp/fund/bond_record')},
					{name:'线下充值',path:'/fund/offline_recharge',component:()=>import('@/page/miniapp/fund/offline_recharge')},
				]},
				{name:'插件',path:'',component:commonView ,children:[
					{name:'插件-列表',path:'/plugin/list',component:()=>import('@/page/miniapp/plugin/list'),meta:{keepAlive:true}},
					{name:'插件-订单',path:'/plugin/order',component:()=>import('@/page/miniapp/plugin/order')},
				]},
				{name:'官网',path:'',component:commonView ,children:[
					{name:'自定义页面',path:'/website/pagelist',component:()=>import('@/page/miniapp/website/pagelist'),meta:{keepAlive:true}},
					{name:'设置',path:'/website/basicset',component:()=>import('@/page/miniapp/website/basicset')}
				]},
				
			]
		},
		{
			name:'商户管理平台',
			path:'/common',
			component:()=>import('@/page//many_store/common'),
			children:[
				{name:'商户总览',path:'/storeapp',component:()=>import('@/page/many_store/index')},
				{name:'商户-商品',path:'',component:commonView ,children:[
					{name:'商户-商品-列表',path:'/many_store/goods/goodsList',component:()=>import('@/page/many_store/goods/goodsList')},
					{name:'商户-商品-编辑',path:'/many_store/goods/goodsEdit',component:()=>import('@/page/many_store/goods/goodsEdit')},
					{name:'商户-商品-分类',path:'/many_store/goods/typeList',component:()=>import('@/page/many_store/goods/typeList')},
					{name:'商户-商品-服务',path:'/many_store/goods/serviceList',component:()=>import('@/page/many_store/goods/serviceList')},
					{name:'商户-商品-运费',path:'/many_store/frieght/list',component:()=>import('@/page/many_store/frieght/list')},
					{name:'商户-商品-运费编辑',path:'/many_store/set/frieght/edit',component:()=>import('@/page/many_store/frieght/edit')},
				]},
				{name:'商户-监控',path:'',component:commonView ,children:[
					{name:'商户-监控列表',path:'/many_store/live/liveList',component:()=>import('@/page/many_store/live/liveList')},
					{name:'商户-监控编辑',path:'/many_store/live/liveEdit',component:()=>import('@/page/many_store/live/liveEdit')},
					{name:'商户-监控分类',path:'/many_store/live/typeList',component:()=>import('@/page/many_store/live/typeList')},
					{name:'商户-监控预览',path:'/many_store/live/live_preview',component:()=>import('@/page/many_store/live/live_preview')},
				]},
				{name:'商户-认养',path:'',component:commonView ,children:[
					{name:'商户-认养-列表',path:'/many_store/adopt/list',component:()=>import('@/page/many_store/adopt/list')},
					{name:'商户-认养-分类',path:'/many_store/adopt/type',component:()=>import('@/page/many_store/adopt/type')},
					{name:'商户-认养-编辑',path:'/many_store/adopt/edit',component:()=>import('@/page/many_store/adopt/edit')},
					{name:'商户-认养-订单',path:'/many_store/adopt/orderList',component:()=>import('@/page/many_store/adopt/orderList')},
					{name:'商户-认养-订单详情',path:'/many_store/adopt/orderDetail',component:()=>import('@/page/many_store/adopt/orderDetail')},
					{name:'商户-认养-管理',path:'/many_store/adopt/manager',component:()=>import('@/page/many_store/adopt/manager')},
					{name:'商户-添加操作类型',path:'/many_store/adopt/work_order',component:()=>import('@/page/many_store/adopt/work_order')},
					{name:'商户>认养>配送订单',path:'/many_store/adopt/delivery_order',component:()=>import('@/page/many_store/adopt/delivery_order')},
				]},
				{name:'商户-土地',path:'',component:commonView ,children:[
					{name:'商户-土地-管理',path:'/many_store/land/landList',component:()=>import('@/page/many_store/land/landList')},
					{name:'商户-土地-编辑',path:'/many_store/land/landEdit',component:()=>import('@/page/many_store/land/landEdit')},
					{name:'商户-土地-分类',path:'/many_store/land/landType',component:()=>import('@/page/many_store/land/landType')},
					{name:'商户-种子管理',path:'/many_store/land/seedList',component:()=>import('@/page/many_store/land/seedList')},
					{name:'商户-种子编辑',path:'/many_store/land/seedEdit',component:()=>import('@/page/many_store/land/seedEdit')},
					{name:'商户-种植订单',path:'/many_store/land/landOrder',component:()=>import('@/page/many_store/land/landOrder')},
					{name:'商户-种植管理',path:'/many_store/land/orderList',component:()=>import('@/page/many_store/land/orderList')},
					{name:'商户-种植订单详情',path:'/many_store/land/orderDetail',component:()=>import('@/page/many_store/land/orderDetail')},
					{name:'商户-服务管理',path:'/many_store/land/service',component:()=>import('@/page/many_store/land/service')},
					{name:'商户-服务管理-编辑',path:'/many_store/land/service_edit',component:()=>import('@/page/many_store/land/service_edit')},
					{name:'商户-配送管理',path:'/many_store/land/delivery',component:()=>import('@/page/many_store/land/delivery')},
					{name:'商户-种植订单详情',path:'/many_store/land/plant_detail',component:()=>import('@/page/many_store/land/plant_detail')},
					{name:'商户-配送订单详情',path:'/many_store/land/delivery_detail',component:()=>import('@/page/many_store/land/delivery_detail')},
					{name:'商户-工单',path:'/many_store/land/workOrder',component:()=>import('@/page/many_store/land/workOrder')},
					{name:'商户-添加编辑土地订单服务',path:'/many_store/land/addWorkService',component:()=>import('@/page/many_store/land/addWorkService')},
					{name:'商户-巡检员',path:'/many_store/land/listPolling',component:()=>import('@/page/many_store/land/listPolling')},
					{name:'商户-交换订单',path:'/many_store/land/change',component:()=>import('@/page/many_store/land/change')},
					{name:'商户-交换订单详情',path:'/many_store/land/change_detail',component:()=>import('@/page/many_store/land/change_detail')}
				]},
				{name:'商户-订单',path:'',component:commonView ,children:[
					{name:'商户-订单列表',path:'/many_store/order/orderList',component:()=>import('@/page/many_store/order/orderList')},
					{name:'商户-订单详情',path:'/many_store/order/detail',component:()=>import('@/page/many_store/order/detail')},
					{name:'商户-售后订单',path:'/many_store/order/sale',component:()=>import('@/page/many_store/order/sale')},
				]},
				{name:'商户-营销',path:'',component:commonView ,children:[
					{name:'商户-优惠券',path:'/many_store/marketing/couponList',component:()=>import('@/page/many_store/marketing/couponList')},
					{name:'商户-优惠券-编辑',path:'/many_store/marketing/couponEdit',component:()=>import('@/page/many_store/marketing/couponEdit')},
				]},
				{name:'商户-资金',path:'',component:commonView ,children:[
					{name:'商户-提现',path:'/many_store/fund/withdraw',component:()=>import('@/page/many_store/fund/withdraw')},
					{name:'商户-资金记录',path:'/many_store/fund/fundRecord',component:()=>import('@/page/many_store/fund/fundRecord')},
					{name:'商户-资金总览',path:'/many_store/fund/pandect',component:()=>import('@/page/many_store/fund/pandect')},
					{name:'商户-账单记录',path:'/many_store/fund/bill',component:()=>import('@/page/many_store/fund/bill')},
				]},
				{name:'商户-订货',path:'',component:commonView ,children:[
					{name:'商户-进货',path:'/many_store/buy/index',component:()=>import('@/page/many_store/buy/index')},
				]},
				{name:'商户-通用信息',path:'',component:commonView ,children:[
					{name:'商户-我的信息',path:'/many_store/public/myInfo',component:()=>import('@/page/many_store/public/myInfo')},
				]}
			]
		}
	],
	history: routerHistory
})

export default router
