<template>
	<div class="kd-map" style="display:inline-block;">
		<div v-if="custom" @click="showMap" >
			<slot></slot>
		</div>
		<div v-else style="width:100%">
			<a-input v-model:value="lnglat" disabled>
				<template #addonAfter>
					<div @click="showMap" style="cursor: pointer;">选择地址</div>
				</template>
			</a-input>
		</div>
		<a-modal centered v-model:visible="show.map" title="选择地址" @ok="getAddressInfo" width="900px" @cancel="show.map = false">
			<div class="kdmp-search-box">
				<a-input class="kdmp-search-input" placeholder="搜索地址" v-model:value="keyword" id="input_id"></a-input>
			</div>
			<div id="container" style="width:850px; height:400px" ></div>
		</a-modal>
	</div>
</template>

<script>
import AMap from 'AMap' // 引入高德地图
export default{
	name:'kd-map',
	data(){
		return{
			map:null,
			marker:null,	//当前标记对象
			info:{
				country:'',
				province:'',	//省
				city:'',		//市
				district:'',	//区
				address:'',		//地址
				citycode:'',	//城市编码 023 
				adcode:'',		//城市编码 "440105"
				latitude:'',
				longitude:'',
			},
			keyword:"",
			show:{
				map:false
			},
			lnglat:"",
		}
	},
	props:{
		value:{
			type:String,
		},
		custom:{
			type:Boolean,
			default:false
		}
	},
	watch:{
		value:{
			deep: true,
			immediate: true,
			handler(val){
				if( val !="" && val!="," && val){
					console.log('str',val);
					let arr = val.split(',')
					if( arr[0]  && arr[0] !='null' && arr[1]  && arr[1] !='null' ){
						this.lnglat = val
					}
				}
			},
		},
	},
	methods: {
		showMap(){
			let t = this
			t.show.map = true
			if( !t.map ){
				let timer = setTimeout(function(){
					t.mapInit()
					clearTimeout(timer)
				},500)
			}
		},
		mapInit(){
			let t = this
			var map = new AMap.Map('container', {
				zoom:13,//级别
				center: [116.397428, 39.90923],//中心点坐标
				viewMode:'3D'//使用3D视图
			});
			
			if( t.value !="" && t.value && t.value!="," ){
				console.log('str',t.value);
				
				let str = t.value.split(",")
				if( str[0]  && str[0] !='null' && str[1]  && str[1] !='null' ){
					map.setZoomAndCenter(14, [str[0], str[1]]);
					t.addMarker({lng:str[0],lat:str[1]} ,"")
				}
				
			}else{
				//定位
				AMap.plugin('AMap.Geolocation', function() {
					var geolocation = new AMap.Geolocation({
						// 是否使用高精度定位，默认：true
						enableHighAccuracy: true,
						// 设置定位超时时间，默认：无穷大
						timeout: 10000,
						// 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
						buttonOffset: new AMap.Pixel(10, 20),
						//  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
						zoomToAccuracy: true,     
						//  定位按钮的排放位置,  RB表示右下
						buttonPosition: 'RB'
					})
					map.addControl(geolocation);
					geolocation.getCurrentPosition(function(status,result){
						if(status=='complete'){
							onComplete(result)
						}else{
							onError(result)
						}
					});
				
					function onComplete (data) {
						// data是具体的定位信息
					}
				
					function onError (data) {
						// 定位出错
					}
				})
			}
			
			//搜索
			var autoOptions = {
				// input 为绑定输入提示功能的input的DOM ID
				input: 'input_id'
			}
			AMap.plugin(['AMap.PlaceSearch','AMap.AutoComplete'], function(){
				var auto = new AMap.AutoComplete(autoOptions);
				var placeSearch = new AMap.PlaceSearch({
					map: map
				});  //构造地点查询类
				auto.on("select", select);//注册监听，当选中某条记录时会触发
				function select(e) {
					placeSearch.setCity(e.poi.adcode);
					placeSearch.search(e.poi.name);  //关键字查询查询
				}
			});
			
			map.on('click', function(ev) {
				let pos ={
					lng:ev.lnglat.lng,
					lat:ev.lnglat.lat,
				}
				t.addMarker(pos,"")
			});
			t.map = map
		},
		
		/**
		 * 添加地图标记信息
		 * @param {Object} location 经纬度
		 * @param {Object} name	地址名称
		 */
		addMarker( location,name ){
			let t = this ,{ map ,marker } = t
			console.log('location',location);
			if( marker ){
				t.marker.setMap(null);
				t.marker = null;
			}
			t.marker = new AMap.Marker({
				position: new AMap.LngLat(location.lng,location.lat),
				offset: new AMap.Pixel(-10, -10),
				title:name
			});
			t.marker.setMap(map);
			t.addressGeocoder(location)
		},
		
		addressGeocoder(lnglat){
			let t = this
			console.log('lnglat',lnglat);
			
			t.info.longitude = lnglat.lng
			t.info.latitude = lnglat.lat
			AMap.plugin('AMap.Geocoder', function() {
				var geocoder = new AMap.Geocoder({
					// city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
					// city: '010'
				})
				geocoder.getAddress(lnglat, function(status, result) {
					if (status === 'complete' && result.info === 'OK') {
						// result为对应的地理位置详细信息
						if (result && result.regeocode) {
							t.info.address = result.regeocode.formattedAddress;
							t.info.country = result.regeocode.addressComponent.country
							t.info.province = result.regeocode.addressComponent.province
							t.info.city = result.regeocode.addressComponent.city
							t.info.district = result.regeocode.addressComponent.district
							t.info.citycode = result.regeocode.addressComponent.citycode
							t.info.adcode = result.regeocode.addressComponent.adcode
						}
						console.log("地址详细信息",t.info);
					}
				})
			})
		},
		
		getAddressInfo(){
			let t = this ,{ info } = t
			console.log(t.info);
			if( info.latitude == "" || info.longitude =="" ){
				t.$message.warning("请选择地址")
				return
			}
			t.lnglat = info.longitude+','+info.latitude
			t.show.map = false
			t.$emit("change",info)
		}
	}
}
</script>

<style lang="scss">
	.kdmp-search-input{
		width: 300px;
		position: absolute;
		z-index: 999;
		margin-top: 12px;
		margin-left: 12px;
	}
</style>
