import siteinfo from './global.js'
export default class Util{
	constructor() {}
	/**
	 * 获取本地图片资源路径
	 * @param {Object} url	图片名称
	 */
	getStaticSrc(url){
		let { siteroot } = siteinfo.siteinfo
		let src = siteroot.split('//')[0]+'//'+siteroot.split('//')[1].split('/')[0]+'/mini/'		
		return src+url
	}
	
	payMethod(e){
		let str = ""
		switch(e){
			case 1:
				str =  '微信小程序支付'
				break;
			case 2:
				str =  '微信APP支付'
				break;
			case 3:
				str =  '支付宝支付'
				break;
			case 4:
				str =  '余额支付'
				break;
			case 5:
				str =  '支付宝APP支付'
				break;
			case 6:
				str =  '微信H5支付'
				break;
			case 7:
				str =  '赊账'
				break;
			case 66:
				str =  '光大银行支付'
				break;
			case 'wx':
				str =  '微信支付'
				break;
			default:
				str =  "未支付"
				break;
		}
		return str
	}
	
}