import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import store from '@/store'
class Common{
    /**
     * 获取数据列表并组装
     * @param {string} api 接口地址
     * @param {obect}  data 请求参数
     * @param {number} rank 
     * @returns 
     */
    getDataList(api,data,rank=2) {
        return new Promise((resolve,reject)=>{
            $post(api,data,false,rank).then(res=>{
                let data = {
                    list:res.data,
                    page: parseInt(res.page.currentPage),
                    count:parseInt(res.page.totalCount) 
                }
                resolve(data);
            },()=>{
                reject('获取列表数据出错')
            })
        })
    }

    /**
     * 删除数据信息
     * @param {number} pk    id
     * @param {number} type  删除类型
     * @param {string} title 删除提示文字
     * @returns 
     */
    deleteDataList(pk,type,title){
        return new Promise(resolve=>{
            if( !pk ){
                tool.message('请选择要删除的内容','warning');
                resolve(false)
                return
            }	
            tool.confirm(title).then(()=>{
                $post("deleteLandSomeOne",{pk,type}).then(res=>{
                    if( res.errorCode  == 0 ){
                        tool.message("删除成功","success")
                        resolve(res.data)
                    }
                })
            }).catch(()=>{})
        })
    }

    /**
     * 保存或修改自定义页面数据
     * @param {string} sence 场景 set-default=设置默认 edit=编辑或新增
     * @param {object} data 要修改的数据
     */
    addOrEditCustomPage(sence,data){
        return new Promise((resolve,reject)=>{
            if( sence == "set-default"){
                tool.confirm("确认将该页面设置为默认首页吗？").then(()=>{
                    $post("createOrUpdatePage",data).then(()=>{
                        tool.message("操作成功","success")
                        resolve(true)
                    })
                }).catch(()=>{
                    reject(false)
                })
            }
        })
    }

    /**
     * 复制自定义页面
     * @param {number} id  目标id
     * @returns 
     */
    copyCustomPage(id){
        return new Promise((resolve,reject)=>{
            tool.confirm("确认要复制当前选择的页面吗?").then(()=>{
                $post("copyPageDetail",{id}).then(()=>{
                    tool.message("操作成功","success")
                    resolve(true)
                })
            }).catch(()=>{
                reject(false)
            })
        })
    }

    /**
     * 保存轮播图信息
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditSlide(param,fn){
        if( !param.id) delete param.id
        $post("createOrUpdateSlide",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 打印订单
     * @param {number} order_id 
     * @param {number} type  type:4 种植订单 3认养订单
     */
    printOrder(order_id,type){
        tool.confirm("确认打印订单信息吗？?").then(()=>{
            $post("wPrint",{pk:order_id,type}).then(()=>{
                tool.message("订单已打印")
            })
        }).catch(()=>{
        })
    }

    /**
     * 获取订单日志
     * @param {string} type     adopt_order认养订单
     * @param {number} order_id 
     * @param {function} fn 
     */
    getOrderLog(type,order_id,fn){
        let param = { op:type,order_id}
        $post("getOrderLogs",param).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 获取消息通知
     * @param {number}} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getNewMessge(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.type ) param.type = extra.type
        this.getDataList('getNewMsg',param).then(res=>{
            fn(res)
        })
    }

    /**
     * 获取图片信息
     * @param {number} page 
     * @param {function} limit 
     */
    getImageList(page, limit,extra,fn) {
        let param = { page,limit,sort:1}
        if( extra && extra.type ) param.type = extra.type  //type = 2视频
        $post('getFileList', param).then(res => {
            let data = {
                list: res.data,
                page: res.page.currentPage,
                count: res.page.totalCount,
                size:res.info
            }
            fn(data)
        })
    }

    getStreaming(page,limit,fn){
        $post('LiveList',{page,limit}).then(res=>{
            let d = res.data
            if( typeof d =='string' ){
                let result = JSON.parse(d);
                if( result.errcode != 0 ){
                    tool.message(result.errmsg,"warning") 
                    return
                }
                fn({list:result.room_info,count:result.tool,page})
                return
            }
            fn({list:[],count:0,page:1})
        })
    }

    /**
     * 获取物流信息
     * @param {number} id 
     * @param {string} order_type 
     * @param {function} fn 
     */
    getLogistics(id,order_type,fn){
        $post('getOrderExpressTrance',{ pk:id,order_type } ).then(res=>fn(res.data))
    }

    /**
     * 查询订单支付状态
     * @param {string} type 
     * @param {number} id 
     */
    queryOrderPay(type,id,fn){
        $post('searchOrderPayStatus',{order_id:id,type},false).then(res=>fn(res)).catch(err=>fn(err))
    }

    /**
     * 获取消息列表
     * @param {*} page 
     * @param {*} limit 
     * @param {*} cb 
     */
    getMessage(page,limit,cb){
        let param = { page,limit } 
        commonModel.getDataList("MessageList",param,1).then(res=>cb(res))
    }

    /**
     * 读取消息
     * @param {string} sence  read 读取消息  view查看消息 readall设置为全读
     * @param {Object} extra 
     * @param {function} cb 
     */
    hanldeMessage(sence,extra, cb){
        if( sence == 'read' ){  //读取消息
            $post('notice',{},true,1).then(res=>{
                store.dispatch('setStatisticsInfo',res.data)
                cb(res.data)
            })
        }

        if( sence == 'view'){   //查看消息
            $post('viewMessage',extra,true,1).then(res=>cb(res.data))
        }

        if( sence == 'readall' ){   //设置全部为已读
            tool.confirm("确认要将未读的信息全部标记为已读吗?").then(()=>{
                $post('pickAllRead',{},true,1).then(res=>{
					tool.message('操作成功');
					cb(res.data)
				})
            }).catch(()=>{})
        }

        if( sence == 'isread' ){
            $post('MessageIsRead', extra).then(res => cb(res.data));
        }
        
    }

    //更新缓存
    clearCache(){
        $post('clearCache',{},true,1).then(()=>{
            tool.message("缓存已更新")
        })
    }
    
    /**
     * 获取产品更新日志
     * @param {function} cb 
     */
    getHistoryLog(page=1,limit=999,cb) {
        commonModel.getDataList("getHistoryLog",{page,limit},1).then(res=>cb(res))
    }

    /**
     * 给订单设置国标设备
     * @param {object} param 
     * @param {function} cb 
     * @returns 
     */
    setOrderDevice(param,cb){
        if( !tool.returnMessage(param.device_id,"请选择设备")) return
        $post("setOrderDevice",param).then(res=>{
            tool.message("设置成功")
            cb(res.data)
        }).catch(()=>{})
    }
}
const commonModel = new Common()
export default commonModel

