import {createStore} from 'vuex'

const  store  =  createStore({ 
	state:{ 
		token: "",
		userId:0,
		username:null,		//当前登录用户名称
		userInfo:null,
		permissionList:null,//最外层路由
		permPlatform:null,	//小程序/APP 管理端路由
		permRoute:null,		//允许访问的路由
		modulesPerm:{},		//模块权限
		statisticsInfo:null,//统计信息
		loading:false,		//容器是否加载中
		subimiting:false,	//表单提交节流
		
		//小程序端信息
		miniNav:{			//导航信息
			first:'/miniapp'
		},
		
	} ,
	// 获取状态(变量的值)
	getters: {
		token: state => state.token,
		userId: state => state.userId,
		userInfo: state => state.userInfo,
		permissionList: state => state.permissionList,
		permPlatform: state => state.permPlatform,
		permRoute: state => state.permRoute,
		modulesPerm: state => state.modulesPerm,
		username: state => state.username,
		statisticsInfo: state => state.statisticsInfo,
		miniNav: state => state.miniNav,
		loading: state => state.loading,
		subimiting: state => state.subimiting,
	},
	
	// 修改state里面的变量(修改变量的值)
	mutations: {
		setToken(state, data) {
			state.token = data
		},
		setUserId(state, data) {state.userId = data},
		setUserInfo(state, data) {state.userInfo = data},
		setPermissionList(state, data) {state.permissionList = data},
		setPermPlatform(state, data) {state.permPlatform = data},
		setPermRoute(state, data) {state.permRoute = data},
		setModulesPerm(state, data) {state.modulesPerm = data},
		setUsername(state, data) {state.username = data},
		setStatisticsInfo(state, data) {state.statisticsInfo = data},
		setMiniNav(state, data) {state.miniNav = data},
		setLoading(state, data) {state.loading = data},
		setSubimiting(state, data) {state.subimiting = data},
	},
	
	// Action 触发 mutation 函数,从而修改状态
	actions: {
		setToken({ commit}, data) {commit("setToken", data)},
		setUserId({ commit}, data) {commit("setUserId", data)},
		setUserInfo({ commit}, data) {commit("setUserInfo", data)},
		setPermissionList({ commit}, data) {commit("setPermissionList", data)},
		setPermPlatform({ commit}, data) {commit("setPermPlatform", data)},
		setPermRoute({ commit}, data) {commit("setPermRoute", data)},
		setModulesPerm({ commit}, data) {commit("setModulesPerm", data)},
		setUsername({ commit}, data) {commit("setUsername", data)},
		setStatisticsInfo({ commit}, data) {commit("setStatisticsInfo", data)},
		setMiniNav({ commit}, data) {commit("setMiniNav", data)},
		setLoading({ commit}, data) {commit("setLoading", data)},
		setSubimiting({ commit}, data) {commit("setSubimiting", data)},
	},
})

export default store
