 let siteinfo={
	// siteroot:'http://farm.test.farmkd.com/index.php'
	siteroot:'https://farmdev.farmkd.com/index.php'
	// siteroot:'https://kilolo.vip/index.php'
	//siteroot:'http://com.backend.farm/index.php'
	// siteroot:'http://farm.kd.com/index.php'
	// siteroot:'http://farm.farmkd.com/index.php'
}
export default {
	siteinfo
}
